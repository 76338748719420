<template>
    <div :class="{ 'footer-light-b': !trasparentFooter, 'footer-tra-b': trasparentFooter }" class="my-2">
        <b-row class="footer-copyright d-flex justify-content-center align-items-center">
            <b-col cols="auto">
                <p class="mb-0">&copy; MOMITNOW PVT LTD. All Rights Reserved. </p>
            </b-col>
            <b-col cols="auto">

                <b-link :to="{ path: '/terms-and-condition' }">
                    {{ $t('Terms of Service') }}
                </b-link>

                <span class="mr-2">&nbsp;</span>

                <b-link :to="{ path: '/privacy-policy' }">
                    {{ $t('Privacy Policy') }}
                </b-link>
            </b-col>
        </b-row>
    </div>
</template>
  
<script>

export default {
    props: {
        trasparentFooter: {
            type: Boolean,
            default: false,
            required: false,
        }
    },
}
</script>
  
<style lang="scss">
.footer-tra-b {
    width: 100%;
    padding: 0.4rem 3rem;
    .footer-copyright {
        padding: 1.8rem 2rem;
        p {
            color: #FFF;
            font-size: 1.3rem;
            margin-bottom: 0;
        }

        a {
            color: #FFF;
            font-size: 1.3rem;
        }

    }
}
@media screen and (max-width: 767px) {
    .footer-tra-b {
    .footer-copyright {
        p {
            font-size: 1rem;
        }

        a {
            font-size: 1rem;
        }

    }
}
}
</style>
  